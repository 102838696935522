@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css');
@import url('https://fonts.googleapis.com/css?family=Anton:regular');
@import url('https://fonts.googleapis.com/css?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

@mixin clearfix {
  content:'';
  visibility:none;
  display:block;
  height:0;
  clear:both;
}

//$corp-font:'Six Caps', serif;
$corp-font:'Anton', serif;
$system-font: 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',  sans-serif;
$icon-font:'Material Symbols Sharp';

//colors
$primary-color: grey;
$secondary-color: yellow;
$tertery-color:black;

//sizes
$micro-size: 0.563rem;
$fly-size: 1rem;
$small-size: 1.77rem;
$med-size:  2.369rem;
$large-size: 3.157rem;
$title-size: 5.61rem;

//based on 1440px grid
$col1:3.75rem;
$col2:8.75rem;
$col3:13.75rem;
$col4:18.75rem;
$col5:23.75rem;
$col6:35rem;
$col12: 87.5rem;
$gutter:1.25rem;

html {
  font-size:100%;
}

body {
  margin: 0;
  font-family: $system-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:$tertery-color;
}

.client-section {
  background-color:white;
}

.icon {
  height:$large-size;
  width:auto;
  margin-right:$fly-size;
  margin-top:$fly-size;
}

.mouse {
  position:fixed !important;
  right:$small-size;
  bottom:$small-size;
	width: 50px;
	height: 90px;
	border: 3px solid #333;
	border-radius: 60px;
	position: relative;
  p {
    position:absolute;
    top:-($small-size);
    text-align:center;
    width:100%;
    color:#333;
  }
	&::before {
		content: '';
		width: 12px;
		height: 12px;
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		background-color: #333;
		border-radius: 50%;
		opacity: 1;
		animation: wheel 2s infinite;
		-webkit-animation: wheel 2s infinite;
	}
}

@keyframes wheel {
	to {
		opacity: 0;
		top: 60px;
	}
}

@-webkit-keyframes wheel {
	to {
		opacity: 0;
		top: 60px;
	}
}

a {
  text-decoration:none;
  color:inherit;
}

.pills {
  li {
    display:inline-block;
    padding:$micro-size $fly-size;
    background-color:white;
    font-size:$micro-size !important;
    text-transform:uppercase;
    color:black;
    clear:none;
    margin:$micro-size;
    border-radius:$micro-size;
    &:first-child {
      margin-left:0;
    }
  }
}

h1,
h2,
h3
h4,
h5 {
  font-family:$corp-font;
  //font-weight:700;
  text-transform:uppercase;
  color:white;
}

.client-detail {
  .client-details-hero {
    background-size:cover !important;
    background-repeat:no-repeat;
    background-position:center right !important;
    .hero-detail {
      position:absolute;
      display:block;
      top:$col4;
      right:0;
      width:100%;
      height:100%;
      background-size:50%;
      background-repeat:no-repeat;
      background-position:top right;
      pointer-events:none;
      h2 {
        font-family:$system-font !important;
      }
    }
    .logo {
      position:relative;
      width:$col4;
      height:$col1*1.4;
      background-size:contain;
      background-repeat:no-repeat;
      background-position:center left;
      margin:$col1;
      margin-left:0;
    }
  }
}

h1 {
  font-size:$col2;
  width:70%;
  text-wrap:balance;
}

h2 {
  font-size:$large-size;
  font-weight:200 !important;
  width:70%;
  margin-top:$small-size;
  margin-bottom:$small-size;
  text-wrap:balance;
}

H1 + H2 {
  margin-top:$micro-size;
  text-wrap:balance;
}

blockquote {
  margin:0 $large-size 0 $large-size;
  font-style:italic;
}

blockquote + p {
  margin-top:$small-size;
  margin-left:$large-size;
  font-weight:bold;
}

blockquote + p + p {
  margin-top:$fly-size;
  margin-left:$large-size;
  font-size:$fly-size;
}

.balance-text {
  text-wrap:balance;
}

input[type=search] {
  -webkit-appearance: none;
  apperence:none;
}

.header {
  position:fixed;
  top:0;
  left:0;
  right:0;
  height:$large-size;
  .nav-button {
    display:none;
  }
  .logo {
    background-image: url("../images/logo.svg");
    background-size:contain;
    background-repeat:no-repeat;
    display: inline-block;
    width:$col2;
    height:100%;
    margin:$fly-size;
  }
  .nav {
    display:inline-block;
    float:right;
    margin:$micro-size;
    li {
      display:inline-block;
      margin-left:1px;
      padding:$micro-size $fly-size;
      background-color:white;
      font-size:$micro-size !important;
      text-transform:uppercase;
      color:black;
      clear:none;
      a {
        text-decoration:none;
        font-weight:900;
        font-size:$micro-size;
        color:black;
      }
      &:first-child {
        border-radius: $micro-size 0 0 $micro-size;
      }
      &:last-child {
        border-radius: 0 $micro-size $micro-size 0;
      }
    }
  }
}

section {
  display: block;
  padding-top: $title-size;
  min-height: 100vh;
  .section-inner {
    display:block;
    width:87.5rem;
    height:100%;
    margin:auto auto;
    .section-middle {
      display:table-cell;
      width:100%;
      height:80vh;
      vertical-align:middle;
      padding-bottom:$col2;
      font-size:$med-size;
      color:white;
    }
  }
  &:first-of-type {
    padding-top: $title-size;
    z-index:2;
  }
  &:empty {
    display:none;
  }
}

.focus-wrapper {
  h2,
  p {
    display:inline-block;
    width:auto;
    margin-right:$small-size;
  }
  p {
    color:white;
    &::after {
      display:inline-block;
      content: '\e5c8';
      font-family:$icon-font;
      vertical-align:text-bottom;
    }
    &:hover::after {
      margin-left:$micro-size*.5;
    }
  }
}

.gallery-section {
  background-color:#eee;
}

.preview {
  width:100%;
  height:100%;
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center center;
}

.project-list-hero {
  background-color:black;
}

.search-wrapper {
  input[type=search] {
    display:block;
    width:$col6;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E");
    background-size:auto 50%;
    background-position: 95%;
    background-repeat:no-repeat;
    background-color:rgba(white, .2);
    display:inline-block;
    border:none;
    border-radius: $micro-size;
    font-size:$title-size;
    padding:$micro-size*.5 $micro-size;
    color:white;
    animation: pulse 3s ease-in;
    animation-iteration-count: infinite;
    box-shadow: 0px 0px 20px rgba(black, .2);
    &:focus {
      animation:none;
      transform:scale(inherit);
      background-color:white;
      color:$primary-color;
    }
    &::placeholder {
      font-size:$med-size;
      line-height:$title-size;
      font-family:$corp-font;
      color:$tertery-color;
    }
    &::placeholder:focus {
      color:white !important;
    }
  }
}

.project-list {
  display:block;
  width:$col12;
  margin:auto auto;
  .project-card {
    display:block;
    width:100%;
    margin:$gutter;
    padding:$gutter;
    color:white;
    h2 {
      font-size:$title-size;
      filter:brightness(0) invert(1);
      &:hover {
        //font-style:italic;
        //margin-left:$small-size !important;
      }
    }
    p {
      margin-bottom:$micro-size;
    }
    .background-image {
      display: block;
      position: fixed;
      top:0;
      left:0;
      width:100%;
      height:100%;
      z-index:-1;
      background-repeat:no-repeat;
      background-size:cover;
    }
    &:hover {
      h2 {
        opacity:.8;
        filter:brightness(100%) invert(0)
      }
    }
  }
}

.preview-wrapper {
  position: relative;
}

.zoom-in > input, 
.zoom-in > p, 
.zoom-in > h1, 
.zoom-in > h2, 
.zoom-in > blockquote, 
.zoom-in > div {
  position:relative;
  animation: zoomin 1.5s;
}


.zoom-out > input, 
.zoom-out > p, 
.zoom-out > h1, 
.zoom-out > blockquote, 
.zoom-out > div {
  position:relative;
  animation: zoomout 1.5s;
  opacity:0;
}

.lg-react-element {
  display:block;
  padding:$small-size;
  margin:auto auto;
  width:$col12;
  .gallery-item {
    position:relative;
    display:inline-block;
    vertical-align:top;
    height:500px;
    width:calc( 50% - $small-size );
    margin:$small-size/2;
    text-decoration:none;
    color:black;
    .preview {
      position:relative;
      width:100%;
      height:$title-size*4;
      background-color:white;
      background-size:cover;
    }
    .image-description {
      margin-top:$small-size;
      font-size:$fly-size;
      text-transform:uppercase;
      color:black;
    }
  }
}


@keyframes zoomout {
  0% {
    opacity:1;
    top:0px;
  }

  30% {
    opacity:0;
    top:50px;
  }

  100% {
    opacity:0;
    top:50px;
  }
}

@keyframes zoomin {
  0% {
    opacity:0;
    top:50px
  }

  30% {
    opacity:0;
    top:50px;
  }

  100% {
    opacity:1;
    top:0;
  }
}

@media screen and (max-width: 325px) {
  html {
    font-size: 45%;
  }
}

@media screen and (min-width: 326px) and (max-width: 700px) {
  html {
    font-size: 40%;
  }
}

@media screen and ( orientation: portrait ) {
  html {
    font-size: 55%;
  }
  section {
    width:100vw;
    .section-inner {
      width:95vw;
      .project-list {
        width:95vw;
      }
    }
  }
  .gallery-section {
    .lg-react-element {
      width:100vw;
      .gallery-item {
        display:block;
        width:100%;
        margin:0;
        margin-bottom:$small-size;
        height:auto;
      }
    }
  }
}

.dec0de {
  header {
    .logo {
      background-image: url("../images/decode-pixel2.gif");
      width:$title-size*1.5;
      height:$small-size;
    }
    .nav {
      .nav-item:nth-child(2) {
        display:none;
      }
    }
  }
}

@media ( orientation: portrait ) {
  .header {
    .nav {
      .nav-item {
        a {
          font-size:$small-size*.6;
        }
      }
    }
  }
  .dec0de {
    .header {
      .logo {
        width:$title-size*4;
        height:$large-size*1.25;
      }
    }
  }
  .project-list {
    .project-card {
      margin:$small-size 0;
      border-bottom:solid thin $primary-color;
      a {
        .zoom-in,
        .zoom-out {
          div {
            position:relative;
            animation:none;
            top:0;
            left:0;
            height:47vw;
            margin-bottom:$small-size;
            opacity:1;
          }
        }
      }
      &:hover {
        h2 {
          color:white !important;
        }
      }
      &:last-child {
        border-bottom:none;
      }
    }
  }
}
